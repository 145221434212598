import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LocalStore } from "../../store";
import { setJwt } from "../../../store/auth";
import { useAppDispatch } from "../../../store";
import { MiviaApi } from "@mivialabs/mivia-api-nodejs";

const PublicLoginForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const dispatch = useAppDispatch();
  const toast = useToast();

  const handleForm = async (ev: any) => {
    ev.preventDefault();

    setIsLoading(true);
    try {
      const res = await MiviaApi.auth.loginByEmail(userEmail, userPassword);

      toast({
        title: "Successfully authenticated.",
        description: "You've been logged in.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      if (res.data) {
        LocalStore.set(`jwt`, res.data.jwtToken);

        MiviaApi.setConfig({ auth: { token: res.data.jwtToken } });

        dispatch(setJwt(res.data.jwtToken));
        // setJwt(res.data.jwtToken);
      }

      navigate("/dashboard");
    } catch (e: any) {
      setIsLoading(false);

      const messageString = e?.message;

      if (messageString) {
        let message: any = messageString;

        console.log(message);
        if (Array.isArray(message)) {
          message.forEach((m: any) => {
            toast({
              description: m,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          });
        } else {
          toast({
            description: message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
  };

  return (
    <>
      <Card className="mt-4">
        <form onSubmit={handleForm}>
          <CardHeader>
            <Heading size="md">Login</Heading>
          </CardHeader>

          <CardBody>
            <FormControl>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                isDisabled={isLoading}
                onChange={(e: any) => {
                  setUserEmail(e.target.value);
                }}
                value={userEmail}
              />
              <FormHelperText>
                Email address which is connected with your user account.
              </FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                isDisabled={isLoading}
                onChange={(e: any) => {
                  setUserPassword(e.target.value);
                }}
                value={userPassword}
              />
              <FormHelperText>
                Password associated with your login method.
              </FormHelperText>
            </FormControl>
          </CardBody>

          <CardFooter placeContent={"end"}>
            <Center>
              <Link to={`/register`}>Create an account</Link> &nbsp; | &nbsp;{" "}
              <Link to={`/password-reset`}>Forgot password</Link> &nbsp;{" "}
              <Button
                bg={`#4366FF`}
                color={`#ffffff`}
                type="submit"
                isDisabled={isLoading}
              >
                Login{" "}
                {isLoading ? (
                  <>
                    {" "}
                    &nbsp; <Spinner />
                  </>
                ) : null}
              </Button>
            </Center>
          </CardFooter>
        </form>
      </Card>
    </>
  );
};

export default PublicLoginForm;
