import { Me } from "@mivialabs/mivia-api-nodejs";

export interface IStore {
  jwt: string | null;
  // wallets:
  //   | {
  //       address: `0x${string}`;
  //     }[]
  //   | null;
  user: Me | null;
  currentOrganizationId: string | null;
  currentProjectId: string | null;
}

export class LocalStore {
  private static storeKey: string = "miviaStore";
  private static store: IStore = {
    jwt: null,
    // wallets: null,
    user: null,
    currentOrganizationId: null,
    currentProjectId: null,
  };

  static get<T>(key: string): T | null {
    LocalStore.validateKey(key);

    try {
      return (LocalStore.store as any)[key] as T;
    } catch (e: any) {
      return null;
    }
  }

  static set(key: string, value: any): boolean {
    LocalStore.validateKey(key);

    (LocalStore.store as any)[key] = value;
    localStorage.setItem(LocalStore.storeKey, JSON.stringify(LocalStore.store));

    return true;
  }

  static delete(key: string): boolean {
    LocalStore.validateKey(key);

    (LocalStore.store as any)[key] = null;
    localStorage.setItem(LocalStore.storeKey, JSON.stringify(LocalStore.store));

    return true;
  }

  private static validateKey(key: string) {
    LocalStore.loadKeyFromLocalStorage(key);

    if (!LocalStore.store.hasOwnProperty(key)) {
      throw new Error(`Invalid key ${key}`);
    }
  }

  private static loadKeyFromLocalStorage(key: string): void {
    const storeText: string | null = localStorage.getItem(LocalStore.storeKey);
    if (!storeText) return;

    const store: IStore = JSON.parse(storeText);

    LocalStore.store = Object.assign({}, store);
    console.log(`LocalStore.store`, LocalStore.store);
  }
}
