import { Spinner, Center, Box } from "@chakra-ui/react";

export function ViewLoader() {
  return (
    <>
      <Box p="5">
        <Center>
          <Spinner />
        </Center>
      </Box>
    </>
  );
}
