import { MiviaApi } from "../mivia-api";
import { Response, Stock, StockUpdate } from "../interfaces";
import { HttpMethod } from "../enums";

export class Stocks {
  /**
   * List product stocks
   *
   * @param productId
   * @returns
   */
  async list(productId: string): Promise<Response<Stock[]>> {
    const res = await MiviaApi.client.call<null, Stock[], null>(
      HttpMethod.GET,
      `product/${productId}/stock`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Stock[]>(res?.data, res?.meta);
  }

  /**
   * Update product stock value at warehouse location
   *
   * @param productId string product id
   * @param payload StockUpdate
   * @returns Stock
   */
  async update(
    productId: string,
    payload: StockUpdate
  ): Promise<Response<Stock>> {
    const res = await MiviaApi.client.call<StockUpdate, Stock, null>(
      HttpMethod.PATCH,
      `product/${productId}/stock`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Stock>(res?.data, res?.meta);
  }
}
