import { MiviaApi } from "../mivia-api";
import { HttpMethod } from "../enums";
import {
  OrganizationCreate,
  Organization,
  OrganizationUpdate,
} from "../interfaces";
import { Response } from "../interfaces/response.interface";
import { Members } from "./members";

export class Organizations {
  member: Members = new Members();

  /**
   * List all ORGANIZATIONS to which current USER / API_KEY has access to
   *
   * @returns Organization[]
   */
  async list(): Promise<Response<Organization[]>> {
    const res = await MiviaApi.client.call<null, Organization[], null>(
      HttpMethod.GET,
      "organization"
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Organization[]>(res?.data, res?.meta);
  }

  /**
   * Create ORGANIZATION assigned to current USER as owner
   *
   * @param payload OrganizationCreate
   * @returns Organization
   */
  async create(payload: OrganizationCreate): Promise<Response<Organization>> {
    const res = await MiviaApi.client.call<
      OrganizationCreate,
      Organization,
      null
    >(HttpMethod.POST, "organization", payload);

    if (res?.error) {
      throw res;
    }

    return Response.create<Organization>(res?.data, res?.meta);
  }

  /**
   * Get ORGANIZATION details by id
   *
   * @param id string
   * @returns Organization
   */
  async get(id: string): Promise<Response<Organization>> {
    const res = await MiviaApi.client.call<null, Organization, null>(
      HttpMethod.GET,
      `organization/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Organization>(res?.data, res?.meta);
  }

  /**
   * Update ORGANIZATION details
   *
   * @param id string
   * @param payload OrganizationUpdate
   * @returns Organization
   */
  async update(
    id: string,
    payload: OrganizationUpdate
  ): Promise<Response<Organization>> {
    const res = await MiviaApi.client.call<
      OrganizationUpdate,
      Organization,
      null
    >(HttpMethod.PATCH, `organization/${id}`, payload);

    if (res?.error) {
      throw res;
    }

    return Response.create<Organization>(res?.data, res?.meta);
  }

  /**
   * Delete ORGANIZATION by id. This action can be done only by ORGANIZATION_OWNER_ROLE.
   *
   * @param id string
   * @returns Organization
   */
  async delete(id: string): Promise<Response<Organization>> {
    const res = await MiviaApi.client.call<null, Organization, null>(
      HttpMethod.DELETE,
      `organization/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Organization>(res?.data, res?.meta);
  }

  /**
   * Request ORGANIZATION ownership transfer
   *
   * @param id organization id
   */
  async requestOwnershipTransfer(id: string): Promise<void> {
    const res = await MiviaApi.client.call<null, undefined, null>(
      HttpMethod.POST,
      `organization/${id}/ownership`
    );

    if (res?.error) {
      throw res;
    }

    return;
  }

  /**
   * Confirm ORGANIZATION ownership transfer
   *
   * @param id organization id
   * @param confirmationCode confirmation code
   * @param signature signature for method type WALLET
   */
  async confirmOwnershipTransfer(
    id: string,
    confirmationCode: string,
    signature?: string
  ): Promise<void> {
    const res = await MiviaApi.client.call<null, undefined, null>(
      HttpMethod.GET,
      `organization/${id}/ownership/confirm/${confirmationCode}`
    );

    if (res?.error) {
      throw res;
    }

    return;

    return;
  }
}
