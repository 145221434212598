import { CommerceBrand } from "./commerce-brand";
import { CommerceProduct } from "./commerce-product";
import { CommerceSettings } from "./commerce-settings";
import { CommerceSupplier } from "./commerce-supplier";
import { Warehouses } from "./warehouses";

export class Commerce {
  settings: CommerceSettings = new CommerceSettings();
  supplier: CommerceSupplier = new CommerceSupplier();
  brand: CommerceBrand = new CommerceBrand();
  product: CommerceProduct = new CommerceProduct();
  warehouse: Warehouses = new Warehouses();
}
