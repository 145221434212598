import {
  Box,
  Card,
  CardBody,
  Divider,
  ListIcon,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import {
  ArrowRight,
  ArrowRightShort,
  CaretRight,
  ChevronDoubleRight,
  House,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import SelectOrganization from "../app-navbar/select-organization";
import SelectProject from "../app-navbar/select-project";

const AppSidebar = () => {
  return (
    <div className="app-sidebar">
      {/* <Box mb={2} rounded={7} p={2} bg={"#4361ee"}> */}
      {/* <Box flex={1}> */}
      <div>
        <SelectOrganization /> <SelectProject />
      </div>

      {/* </Box> */}
      {/* </Box> */}

      <UnorderedList styleType={"none"} p={0} m={0} pl={1} spacing={1}>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/dashboard"}>Dashboard</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/notifications"}>Notifications</Link>
        </ListItem>
      </UnorderedList>
      <Divider />
      <strong>Commerce</strong>
      <UnorderedList styleType={"none"} p={0} m={0} pl={1} spacing={1}>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/commerce/order"}>Orders</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/commerce/product"}>Products</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/commerce/stocks"}>Stocks</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/commerce/customer"}>Customers</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/commerce/brand"}>Brands</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/commerce/supplier"}>Suppliers</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/commerce/shipping"}>Shipping</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/commerce/warehouse"}>Warehouses</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/commerce/settings"}>Settings</Link>
        </ListItem>
      </UnorderedList>
      <Divider />
      {/* <strong>Datasets</strong>
      <UnorderedList styleType={"none"} p={0} m={0} pl={1} spacing={1}>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/dataset"}>Data Buckets</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/dataset/template"}>Templates</Link>
        </ListItem>
      </UnorderedList>
      <Divider /> */}
      <strong>Other</strong>
      <UnorderedList styleType={"none"} p={0} m={0} pl={1} spacing={1}>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/integration"}>Integrations</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/settings/billing"}>Billing</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/settings"}>Settings</Link>
        </ListItem>
        <ListItem>
          <ListIcon as={ChevronDoubleRight} w={2.5} />
          <Link to={"/support"}>Customer Service</Link>
        </ListItem>
      </UnorderedList>
    </div>
  );
};

export default AppSidebar;
