import { MiviaApi } from "../mivia-api";
import { Currency, Response } from "../interfaces";
import { HttpMethod } from "../enums";

export class Currencies {
  async list(): Promise<Response<Currency[]>> {
    const res = await MiviaApi.client.call<null, Currency[], null>(
      HttpMethod.GET,
      `price/currency`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Currency[]>(res?.data, res?.meta);
  }
}
