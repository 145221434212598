import { MiviaApi } from "../mivia-api";
import { HttpMethod } from "../enums";
import {
  OrganizationMember,
  OrganizationMemberInvite,
  OrganizationMemberUpdate,
} from "../interfaces";
import { Response } from "../interfaces";

export class Members {
  async invite(
    organizationId: string,
    payload: OrganizationMemberInvite
  ): Promise<Response<OrganizationMember>> {
    throw "Not implemented";
  }

  /**
   * List ORGANIZATION MEMBERS
   *
   * @param organizationId organization id
   * @returns OrganizationMember[]
   */
  async list(organizationId: string): Promise<Response<OrganizationMember[]>> {
    const res = await MiviaApi.client.call<null, OrganizationMember[], null>(
      HttpMethod.GET,
      `organization/${organizationId}/member`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<OrganizationMember[]>(res?.data, res?.meta);
  }

  /**
   * Get ORGANIZATION MEMBER details
   *
   * @param organizationId organization id
   * @param memberId member id
   * @returns OrganizationMember
   */
  async get(
    organizationId: string,
    memberId: string
  ): Promise<Response<OrganizationMember>> {
    const res = await MiviaApi.client.call<null, OrganizationMember, null>(
      HttpMethod.GET,
      `organization/${organizationId}/member/${memberId}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<OrganizationMember>(res?.data, res?.meta);
  }

  /**
   * Update ORGANIZATION MEMBER
   *
   * @param organizationId organization id
   * @param memberId member id
   * @param payload OrganizationMemberUpdate
   * @returns OrganizationMember
   */
  async update(
    organizationId: string,
    memberId: string,
    payload: OrganizationMemberUpdate
  ): Promise<Response<OrganizationMember>> {
    const res = await MiviaApi.client.call<
      OrganizationMemberUpdate,
      OrganizationMember,
      null
    >(
      HttpMethod.PATCH,
      `organization/${organizationId}/member/${memberId}`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<OrganizationMember>(res?.data, res?.meta);
  }

  /**
   * Delete ORGANIZATION MEMBER
   *
   * @param organizationId organization id
   * @param memberId member id
   * @returns OrganizationMember
   */
  async delete(
    organizationId: string,
    memberId: string
  ): Promise<Response<OrganizationMember>> {
    const res = await MiviaApi.client.call<null, OrganizationMember, null>(
      HttpMethod.DELETE,
      `organization/${organizationId}/member/${memberId}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<OrganizationMember>(res?.data, res?.meta);
  }
}
