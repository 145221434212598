import CryptoJS from "crypto-js";
import { MiviaApi } from "../src";
import { HttpMethod } from "./enums/http-method.enum";
import { Response } from "./interfaces/response.interface";

export class Client {
  async call<RequestBodyType, ResponseDataType, RequestQueryType>(
    httpMethod: HttpMethod,
    endpointUrl: string,
    payload?: RequestBodyType,
    query?: RequestQueryType,
    formData?: FormData
  ): Promise<Response<ResponseDataType> | undefined> {
    const headers: Record<string, string> = {};

    if (!formData) {
      headers["Content-Type"] = "application/json";
    }

    if (MiviaApi.config.auth && !MiviaApi.config.api) {
      headers["Authorization"] = `Bearer ${MiviaApi.config.auth.token}`;
    }

    if (MiviaApi.config.api && !MiviaApi.config.auth) {
      headers["x-api-key"] = `${MiviaApi.config.api.keyPublic}`;

      if (payload) {
        const stringToSign = `${MiviaApi.config.api.keyPublic}${JSON.stringify(
          payload
        )}`;

        const signature = this.signSignature(
          MiviaApi.config.api.keySecret,
          stringToSign
        );

        headers["x-api-sig"] = `${signature}`;
      }
    }

    let queryString = "";

    if (query) {
      let i = 0;
      queryString = "?";

      for (const key of Object.keys(query)) {
        // @ts-ignore
        const qparam = query[key];

        if (Array.isArray(qparam)) {
          for (const qParamValue of qparam) {
            queryString += `${i > 0 ? `&` : ``}${key}[]=${qParamValue}`;
            i++;
          }
        } else {
          queryString += `${i > 0 ? `&` : ``}${encodeURIComponent(
            key
          )}=${encodeURIComponent(qparam)}`;
          i++;
        }
      }
    }

    const res = await fetch(
      `${MiviaApi.config.url}/${MiviaApi.config.version}/${endpointUrl}${queryString}`,
      {
        headers: headers,
        method: httpMethod,
        body: formData
          ? formData
          : payload
          ? JSON.stringify(payload)
          : undefined,
      }
    );

    if (res && res.body) {
      try {
        return { ...((await res.json()) as ResponseDataType) } as any;
      } catch (e: any) {
        return;
      }
    }

    return;
  }

  signSignature(secretKey: any, stringToSign: string): string {
    return CryptoJS.HmacSHA256(stringToSign, secretKey).toString();
    // return createHmac("SHA256", secretKey).update(stringToSign).digest("hex");
  }

  verifySignature(
    secretKey: any,
    stringToSign: any,
    signature: string
  ): boolean {
    const expectedSignature = this.signSignature(secretKey, stringToSign);

    if (expectedSignature === signature) {
      return true;
    }

    return false;
  }
}
