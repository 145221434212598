import { Navigate } from "react-router";
import { LocalStore } from "../../store";
import { setJwt, setUser } from "../../../store/auth";
import { useAppDispatch } from "../../../store";

const PublicLogoutView = () => {
  const dispatch = useAppDispatch();

  LocalStore.delete("jwt");

  dispatch(setUser(null));
  dispatch(setJwt(null));

  return <Navigate to={"/"} />;
};

export default PublicLogoutView;
