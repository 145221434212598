import { Currency, Language } from "@mivialabs/mivia-api-nodejs";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface Ecommerce {
  settings: EcommerceSettings | null;
  locations: any[];
}

export interface EcommerceSettings {
  language: string;
  currency: string;
}

export interface AppState {
  currentContext: CurrentContext;
  projects: any[] | null;
  languages: Language[] | null;
  currencies: Currency[] | null;
  ecommerce: Ecommerce;
}

export interface CurrentContext {
  organizationId: string | null;
  projectId: string | null;
}

const initialState: AppState = {
  currentContext: {
    organizationId: null,
    projectId: null,
  },
  projects: [],
  languages: null,
  currencies: null,
  ecommerce: { settings: null, locations: [] },
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setCurrentContext: (state, action: PayloadAction<CurrentContext>) => {
      state.currentContext = action.payload;
    },
    setCurrentOrganizationId: (state, action: PayloadAction<string | null>) => {
      state.currentContext.organizationId = action.payload;
    },
    setCurrentProjectId: (state, action: PayloadAction<string | null>) => {
      state.currentContext.projectId = action.payload;
    },
    setProjects: (state, action: PayloadAction<any[]>) => {
      state.projects = action.payload;
    },
    setLanguages: (state, action: PayloadAction<Language[]>) => {
      state.languages = action.payload;
    },
    setCurrencies: (state, action: PayloadAction<Currency[]>) => {
      state.currencies = action.payload;
    },
    setEcommerceSettings: (state, action: PayloadAction<EcommerceSettings>) => {
      state.ecommerce.settings = action.payload;
    },
  },
});

export const {
  setCurrentContext,
  setCurrentOrganizationId,
  setCurrentProjectId,
  setProjects,
  setLanguages,
  setCurrencies,
  setEcommerceSettings,
} = appSlice.actions;

export const selectCurrentContext = (state: AppState) => state.currentContext;
export const selectCurrentOrganizationId = (state: AppState) =>
  state.currentContext.organizationId;
export const selectCurrentProjectId = (state: AppState) =>
  state.currentContext.projectId;

export default appSlice.reducer;
