import { Stocks } from "./stocks";
import { CommerceProductTranslations } from "./commerce-product-translations";
import { Variants } from "./variants";
import { MiviaApi } from "../mivia-api";
import {
  Product,
  ProductCreate,
  ProductMedia,
  ProductUpdate,
  Response,
} from "../interfaces";
import { HttpMethod } from "../enums";

export class CommerceProduct {
  stock: Stocks = new Stocks();
  translation: CommerceProductTranslations = new CommerceProductTranslations();
  variant: Variants = new Variants();

  /**
   * List PRODUCTS assigned to a PROJECT
   *
   * @param projectId string PROJECT id
   * @returns Product[]
   */
  async list(projectId: string): Promise<Response<Product[]>> {
    const res = await MiviaApi.client.call<null, Product[], null>(
      HttpMethod.GET,
      `product/project/${projectId}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Product[]>(res?.data, res?.meta);
  }

  /**
   * Get PRODUCT by id
   *
   * @param id PRODUCT id
   * @returns Product
   */
  async get(id: string): Promise<Response<Product>> {
    const res = await MiviaApi.client.call<null, Product, null>(
      HttpMethod.GET,
      `product/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Product>(res?.data, res?.meta);
  }

  /**
   * Create PRODUCT
   *
   * @param payload ProductCreate
   * @returns Product
   */
  async create(payload: ProductCreate): Promise<Response<Product>> {
    const res = await MiviaApi.client.call<ProductCreate, Product, null>(
      HttpMethod.POST,
      `product`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Product>(res?.data, res?.meta);
  }

  /**
   * Update PRODUCT by id
   *
   * @param id string
   * @param payload ProductUpdate
   * @returns Product
   */
  async update(id: string, payload: ProductUpdate): Promise<Response<Product>> {
    const res = await MiviaApi.client.call<ProductUpdate, Product, null>(
      HttpMethod.PATCH,
      `product/${id}`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Product>(res?.data, res?.meta);
  }

  /**
   * Delete PRODUCT
   *
   * @param id string PRODUCT id
   * @returns Product
   */
  async delete(id: string): Promise<Response<Product>> {
    const res = await MiviaApi.client.call<null, Product, null>(
      HttpMethod.DELETE,
      `product/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Product>(res?.data, res?.meta);
  }

  async deleteMedia(
    productId: string,
    fileId: string
  ): Promise<Response<ProductMedia>> {
    const res = await MiviaApi.client.call<null, ProductMedia, null>(
      HttpMethod.DELETE,
      `product/${productId}/media/${fileId}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<ProductMedia>(res?.data, res?.meta);
  }
}
