import { MiviaApi } from "../mivia-api";
import {
  CommerceSettingsUpdate,
  CommerceSettings as ICommerceSettings,
  Response,
} from "../interfaces";
import { HttpMethod } from "../enums";

export class CommerceSettings {
  /**
   * Get PROJECT settings
   *
   * @param projectId
   * @returns
   */
  async get(projectId: string): Promise<Response<ICommerceSettings>> {
    const res = await MiviaApi.client.call<null, ICommerceSettings, null>(
      HttpMethod.GET,
      `ecommerce/settings/${projectId}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<ICommerceSettings>(res?.data, res?.meta);
  }

  /**
   * Update PROJECT settings
   *
   * @param projectId string
   * @param payload EcommerceSettingsUpdate
   * @returns EcommerceSettings
   */
  async update(
    projectId: string,
    payload: CommerceSettingsUpdate
  ): Promise<Response<ICommerceSettings>> {
    const res = await MiviaApi.client.call<
      CommerceSettingsUpdate,
      ICommerceSettings,
      null
    >(HttpMethod.PATCH, `ecommerce/settings/${projectId}`, payload);

    if (res?.error) {
      throw res;
    }

    return Response.create<ICommerceSettings>(res?.data, res?.meta);
  }
}
