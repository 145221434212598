import {
  Box,
  Divider,
  Flex,
  Heading,
  ListIcon,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { ChevronDoubleRight } from "react-bootstrap-icons";
import { Link, Outlet } from "react-router-dom";

const AppSettingsView = () => {
  return (
    <>
      <Heading as={"h2"} p={3}>
        Settings
      </Heading>

      <Flex minWidth="max-content" p={3}>
        <Box w={"xs"} maxW={"250px"} p={3}>
          <UnorderedList styleType={"none"} p={0} m={0} pl={1} spacing={1}>
            <ListItem>
              <ListIcon as={ChevronDoubleRight} w={2.5} />
              <Link to={"/settings"}>Profile</Link>
            </ListItem>

            <ListItem>
              <ListIcon as={ChevronDoubleRight} w={2.5} />
              <Link to={"/settings/billing"}>Billing</Link>
            </ListItem>

            <ListItem>
              <ListIcon as={ChevronDoubleRight} w={2.5} />
              <Link to={"/settings/api"}>API</Link>
            </ListItem>
            {/* <ListItem>
              <ListIcon as={ChevronDoubleRight} w={2.5} />
              <Link to={"/settings/security"}>Security</Link>
            </ListItem> */}
          </UnorderedList>

          <Divider />

          <strong>Organizations</strong>
          <UnorderedList styleType={"none"} p={0} m={0} pl={1} spacing={1}>
            <ListItem>
              <ListIcon as={ChevronDoubleRight} w={2.5} />
              <Link to={"/settings/organization"}>List all</Link>
            </ListItem>
            <ListItem>
              <ListIcon as={ChevronDoubleRight} w={2.5} />
              <Link to={"/settings/organization/create"}>Create</Link>
            </ListItem>
            <ListItem>
              <ListIcon as={ChevronDoubleRight} w={2.5} />
              <Link to={"/settings/organization/invitation"}>Invitations</Link>
            </ListItem>
          </UnorderedList>

          <Divider />

          <strong>Projects</strong>
          <UnorderedList styleType={"none"} p={0} m={0} pl={1} spacing={1}>
            <ListItem>
              <ListIcon as={ChevronDoubleRight} w={2.5} />
              <Link to={"/settings/project"}>List all</Link>
            </ListItem>
            <ListItem>
              <ListIcon as={ChevronDoubleRight} w={2.5} />
              <Link to={"/settings/project/create"}>Create</Link>
            </ListItem>
          </UnorderedList>
        </Box>
        <Box flex={1}>
          <Outlet />
        </Box>
      </Flex>
    </>
  );
};

export default AppSettingsView;
