import { MiviaApi } from "../mivia-api";
import {
  Supplier,
  Response,
  SupplierCreate,
  SupplierUpdate,
  SupplierListQuery,
} from "../interfaces";
import { HttpMethod } from "../enums";

export class CommerceSupplier {
  /**
   * Get list of SUPPLIERS by PROJECT
   *
   * @param projectIds PROJECT ids array
   * @returns Supplier[]
   */
  async list(projectIds: string[]): Promise<Response<Supplier[]>> {
    const query: SupplierListQuery = { projectIds };

    const res = await MiviaApi.client.call<null, Supplier[], SupplierListQuery>(
      HttpMethod.GET,
      `supplier`,
      null,
      query
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Supplier[]>(res?.data, res?.meta);
  }

  /**
   * Get SUPPLIER details by id
   *
   * @param id SUPPLIER id
   * @returns Supplier
   */
  async get(id: string): Promise<Response<Supplier>> {
    const res = await MiviaApi.client.call<null, Supplier, null>(
      HttpMethod.GET,
      `supplier/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Supplier>(res?.data, res?.meta);
  }

  /**
   * Create SUPPLIER
   *
   * @param payload SupplierCreate
   * @returns Supplier
   */
  async create(payload: SupplierCreate): Promise<Response<Supplier>> {
    const res = await MiviaApi.client.call<SupplierCreate, Supplier, null>(
      HttpMethod.POST,
      `supplier`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Supplier>(res?.data, res?.meta);
  }

  /**
   * Update SUPPLIER details
   *
   * @param id SUPPLIER id
   * @param payload SupplierUpdate
   * @returns Supplier
   */
  async update(
    id: string,
    payload: SupplierUpdate
  ): Promise<Response<Supplier>> {
    const res = await MiviaApi.client.call<SupplierUpdate, Supplier, null>(
      HttpMethod.PUT,
      `supplier/${id}`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Supplier>(res?.data, res?.meta);
  }

  /**
   * Delete SUPPLIER
   *
   * @param id SUPPLIER id
   * @returns Supplier
   */
  async delete(id: string): Promise<Response<Supplier>> {
    const res = await MiviaApi.client.call<null, Supplier, null>(
      HttpMethod.DELETE,
      `supplier/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Supplier>(res?.data, res?.meta);
  }
}
