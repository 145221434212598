import { MiviaApi } from "../mivia-api";
import {
  Brand,
  BrandCreate,
  BrandUpdate,
  BrandListQuery,
  Response,
} from "../interfaces";
import { HttpMethod } from "../enums";

export class CommerceBrand {
  /**
   * List all BRANDS in a PROJECT
   *
   * @param projectIds PROJECTS ids
   * @returns Brand[]
   */
  async list(projectIds: string[]): Promise<Response<Brand[]>> {
    const query: BrandListQuery = { projectIds: projectIds };

    const res = await MiviaApi.client.call<null, Brand[], BrandListQuery>(
      HttpMethod.GET,
      "ecommerce/brand",
      undefined,
      query
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Brand[]>(res?.data, res?.meta);
  }

  /**
   * Get BRAND details by id
   *
   * @param id
   * @returns Brand
   */
  async get(id: string): Promise<Response<Brand>> {
    const res = await MiviaApi.client.call<null, Brand, null>(
      HttpMethod.GET,
      `ecommerce/brand/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Brand>(res?.data, res?.meta);
  }

  /**
   * Create BRAND assigned to a PROJECT
   * @param payload BrandCreate
   * @returns Brand
   */
  async create(payload: BrandCreate): Promise<Response<Brand>> {
    const res = await MiviaApi.client.call<BrandCreate, Brand, null>(
      HttpMethod.POST,
      `ecommerce/brand`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Brand>(res?.data, res?.meta);
  }

  /**
   * Update BRAND details
   *
   * @param id string BAND id
   * @param payload BrandUpdate
   * @returns Brand
   */
  async update(id: string, payload: BrandUpdate): Promise<Response<Brand>> {
    const res = await MiviaApi.client.call<BrandUpdate, Brand, null>(
      HttpMethod.PUT,
      `ecommerce/brand/${id}`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Brand>(res?.data, res?.meta);
  }

  /**
   * Delete BRAND by id
   * @param id BRAND id
   * @returns Brand
   */
  async delete(id: string): Promise<Response<Brand>> {
    const res = await MiviaApi.client.call<null, Brand, null>(
      HttpMethod.DELETE,
      `ecommerce/brand/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Brand>(res?.data, res?.meta);
  }
}
