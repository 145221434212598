import {
  Box,
  Divider,
  Flex,
  Heading,
  ListIcon,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { ChevronDoubleRight } from "react-bootstrap-icons";
import { Link, Outlet } from "react-router-dom";

const CustomersView = () => {
  return (
    <>
      <Heading as={"h2"}>E-commerce - Customers</Heading>

      <Outlet />
    </>
  );
};

export default CustomersView;
