export enum AuthRole {
  SYSTEM_ADMIN_ROLE = "admin",
  USER_ROLE = "user",
  API_KEY_ROLE = "api-key",

  ORGANIZATION_OWNER_ROLE = "organization.owner",
  ORGANIZATION_ADMIN_ROLE = "organization.admin",
  ORGANIZATION_MEMBER_ROLE = "organization.member",

  PROJECT_ADMIN_ROLE = "project.admin",
  PROJECT_MEMBER_ROLE = "project.member",
}
