import { HttpMethod, MiviaApi } from "../../src";
import {
  AccessKey,
  AccessKeyCreate,
  Response,
  AccessKeyUpdate,
} from "../../src/interfaces";
import { ApiBase } from "../api-base";

export class Api extends ApiBase {
  /**
   * Create new API access key
   *
   * @param payload AccessKeyCreate
   * @returns AccessKey
   */
  async createAccessKey(
    payload: AccessKeyCreate
  ): Promise<Response<AccessKey>> {
    const res = await MiviaApi.client.call<AccessKeyCreate, AccessKey, null>(
      HttpMethod.POST,
      `api-key`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create(res?.data, res?.meta);
  }

  /**
   * Get access key by id
   *
   * @param id access key id
   * @returns AccessKey
   */
  async getAccessKey(id: string): Promise<Response<AccessKey>> {
    const res = await MiviaApi.client.call<null, AccessKey, null>(
      HttpMethod.GET,
      `api-key/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create(res?.data, res?.meta);
  }

  async listAccessKeys(): Promise<Response<AccessKey[]>> {
    const res = await MiviaApi.client.call<null, AccessKey[], null>(
      HttpMethod.GET,
      `api-key`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create(res?.data, res?.meta);
  }

  /**
   * Update access key
   *
   * @param id access key id
   * @param payload AccessKeyUpdate
   * @returns AccessKey
   */
  async updateAccessKey(
    id: string,
    payload: AccessKeyUpdate
  ): Promise<Response<AccessKey>> {
    const res = await MiviaApi.client.call<AccessKeyUpdate, AccessKey, null>(
      HttpMethod.PATCH,
      `api-key/${id}`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create(res?.data, res?.meta);
  }

  /**
   * Delete access key by id
   *
   * @param id access key id
   * @returns AccessKey
   */
  async deleteAccessKey(id: string): Promise<Response<AccessKey>> {
    const res = await MiviaApi.client.call<null, AccessKey, null>(
      HttpMethod.DELETE,
      `api-key/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create(res?.data, res?.meta);
  }
}
