import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
} from "@chakra-ui/react";
import { Plus } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useEffect, useState } from "react";
import { LocalStore } from "../../store";
import { setCurrentProjectId, setEcommerceSettings } from "../../../store/app";
import { MiviaApi, Project } from "@mivialabs/mivia-api-nodejs";
import { ChevronDownIcon } from "@chakra-ui/icons";

const SelectProject = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const app = useAppSelector((state) => state.app);
  const currentContext = useAppSelector((state) => state.app.currentContext);
  const [projects, setProjects] = useState([] as Project[]);
  const [currentProjectId, setProjectId] = useState("");

  useEffect(() => {
    (async () => {
      if (user && projects.length < 1) {
        const res = await MiviaApi.project.list();

        if (res.data) {
          setProjects(res.data);
        }
        //       useAppDispatch(setProjects(res.data));
      }
    })();

    if (currentContext.projectId) {
      setProjectId(currentContext.projectId);

      (async () => {
        try {
          const res = await MiviaApi.commerce.settings.get(
            String(currentContext.projectId)
          );

          if (res.data) {
            dispatch(setEcommerceSettings(res.data));
          }
        } catch (e: any) {}
      })();
    }
  }, [user, projects]);

  const currentProject = projects.filter((p) => p.id === currentProjectId);

  return (
    <>
      {/* <Menu>
        <MenuButton
          // width={"100%"}
          px={4}
          py={2}
          transition="all 0.2s"
          borderRadius="md"
          borderWidth="0px"
          _hover={{ bg: "gray.100" }}
          // _expanded={{ bg: "blue.400" }}
          _focus={{ boxShadow: "outline" }}
          textAlign={"left"}
        >
          {currentProject && currentProject.length === 1
            ? currentProject[0].name
            : "Select or create project"}
          <ChevronDownIcon />
        </MenuButton>
        <MenuList>
          {projects.map((p: Project, idx: number) => {
            if (
              app.currentContext.organizationId &&
              (!p.organization ||
                p.organization.id !== app.currentContext.organizationId)
            ) {
              return null;
            }

            if (!app.currentContext.organizationId && p.organization) {
              return null;
            }

            return (
              <MenuItem value={p.id} key={idx}>
                {p.name}
              </MenuItem>
            );
          })}
          <MenuDivider />
          <MenuItem>Create project</MenuItem>
        </MenuList>
      </Menu> */}
      <Select
        variant="outline"
        placeholder="Select Project"
        size={"sm"}
        mb="4"
        maxW={"200px"}
        display={"inline-block"}
        value={currentProjectId}
        onChange={(e: any) => {
          LocalStore.set("currentProjectId", e.target.value);

          dispatch(setCurrentProjectId(e.target.value));

          setProjectId(e.target.value);
        }}
      >
        {projects.map((p: Project, idx: number) => {
          if (
            app.currentContext.organizationId &&
            (!p.organization ||
              p.organization.id !== app.currentContext.organizationId)
          ) {
            return null;
          }

          if (!app.currentContext.organizationId && p.organization) {
            return null;
          }

          return (
            <option value={p.id} key={idx}>
              {p.name}
            </option>
          );
        })}
      </Select>
      {/* {" "}
      <Button
        leftIcon={<Plus />}
        size="sm"
        color={"#4361ee"}
        bg={"#ECEFFD"}
        variant="solid"
        onClick={() => {
          navigate("/settings/project/create");
        }}
      >
        Create Project
      </Button> */}
      {/* <IconButton
        size={"sm"}
        // variant="outline"
        colorScheme="teal"
        aria-label="Call Sage"
        fontSize="20px"
        icon={<Plus />}
      /> */}
    </>
  );
};

export default SelectProject;
