import { MiviaApi } from "../mivia-api";
import { Response } from "../interfaces/response.interface";
import { Project, ProjectCreate, ProjectUpdate } from "../interfaces";
import { HttpMethod } from "../enums";

export class Projects {
  /**
   * List all projects to which current USER / API KEY has access to
   *
   * @returns Project[]
   */
  async list(): Promise<Response<Project[]>> {
    const res = await MiviaApi.client.call<null, Project[], null>(
      HttpMethod.GET,
      `project`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Project[]>(res?.data, res?.meta);
  }

  /**
   * Get PROJECT by id
   *
   * @param id project id
   * @returns Project
   */
  async get(id: string): Promise<Response<Project>> {
    const res = await MiviaApi.client.call<null, Project, null>(
      HttpMethod.GET,
      `project/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Project>(res?.data, res?.meta);
  }

  /**
   * Create PROJECT
   *
   * @param payload ProjectCreate
   * @returns Project
   */
  async create(payload: ProjectCreate): Promise<Response<Project>> {
    const res = await MiviaApi.client.call<ProjectCreate, Project, null>(
      HttpMethod.POST,
      `project`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Project>(res?.data, res?.meta);
  }

  /**
   * Update PROJECT details
   *
   * @param id project id
   * @param payload ProjectUpdate
   * @returns Project
   */
  async update(id: string, payload: ProjectUpdate): Promise<Response<Project>> {
    const res = await MiviaApi.client.call<ProjectUpdate, Project, null>(
      HttpMethod.PATCH,
      `project/${id}`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Project>(res?.data, res?.meta);
  }

  /**
   * Delete PROJECT
   *
   * @param id project id
   * @returns Project
   */
  async delete(id: string): Promise<Response<Project>> {
    const res = await MiviaApi.client.call<ProjectUpdate, Project, null>(
      HttpMethod.DELETE,
      `project/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Project>(res?.data, res?.meta);
  }
}
