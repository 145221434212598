import { ProductTranslation, ProductTranslationUpdate } from "../interfaces";
import { MiviaApi } from "../mivia-api";
import { Response } from "../interfaces";
import { HttpMethod } from "../enums";

export class CommerceProductTranslations {
  /**
   * List product translations
   *
   * @param productId string product id
   * @returns ProductTranslation[]
   */
  async list(
    productId: string
  ): Promise<Response<ProductTranslation[]> | undefined> {
    return;
  }

  /**
   * Get product translation by product id and language code
   *
   * @param productId string product id
   * @param languageCode string language code
   * @returns ProductTranslation
   */
  async get(
    productId: string,
    languageCode: string
  ): Promise<Response<ProductTranslation> | undefined> {
    return;
  }

  /**
   * Update product translation by product id and language code
   *
   * @param productId string product id
   * @param languageCode string language code
   * @param payload ProductTranslationUpdate
   * @returns ProductTranslation
   */
  async update(
    productId: string,
    languageCode: string,
    payload: ProductTranslationUpdate
  ): Promise<Response<ProductTranslation> | undefined> {
    return;
  }

  /**
   * Delete product translation by product id and language code
   *
   * @param productId string product id
   * @param languageCode string language code
   * @param payload ProductTranslationUpdate
   * @returns ProductTranslation
   */
  async delete(
    productId: string,
    languageCode: string
  ): Promise<Response<ProductTranslation>> {
    throw "Not implemented";
  }
}
