import { MiviaApi } from "../mivia-api";
import { Language, Response } from "../interfaces";
import { HttpMethod } from "../enums";

export class Languages {
  async list(): Promise<Response<Language[]>> {
    const res = await MiviaApi.client.call<null, Language[], null>(
      HttpMethod.GET,
      `language`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Language[]>(res?.data, res?.meta);
  }
}
