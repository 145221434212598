import { Heading } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

const ProductsView = () => {
  return (
    <>
      <Heading as={"h2"} p={3}>
        E-commerce - Products
      </Heading>

      <Outlet />
    </>
  );
};

export default ProductsView;
