import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  List,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import AppNavbar from "../../components/app-navbar/app-navbar";
import AppSidebar from "../../components/app-sidebar/app-sidebar";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { LocalStore } from "../../store";
import { setJwt, setUser } from "../../../store/auth";
import {
  setCurrencies,
  setCurrentOrganizationId,
  setCurrentProjectId,
  setLanguages,
} from "../../../store/app";
import { MiviaApi } from "@mivialabs/mivia-api-nodejs";

const localJwt = LocalStore.get<string>("jwt");

const getMe = async () => {
  const me = await MiviaApi.auth.me.get();

  return me.data;
};

const RootApp = () => {
  const jwt = useAppSelector((state) => state.auth.jwt);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const languages = useAppSelector((state) => state.app.languages);
  const currencies = useAppSelector((state) => state.app.currencies);

  useEffect(() => {
    if (jwt) {
      MiviaApi.setConfig({ auth: { token: jwt } });

      if (!user) {
        (async () => {
          try {
            const me = await getMe();

            dispatch(setUser(me as any));
          } catch (e: any) {
            toast({
              description: `There was an error. You've been logged out.`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });

            navigate("/logout");
          }

          const currentOrganizationId = LocalStore.get<string>(
            "currentOrganizationId"
          );

          if (currentOrganizationId) {
            dispatch(setCurrentOrganizationId(currentOrganizationId));
          }

          const currentProjectId = LocalStore.get<string>("currentProjectId");

          if (currentProjectId) {
            dispatch(setCurrentProjectId(currentProjectId));
          }
        })();
      }

      if (!languages) {
        (async () => {
          try {
            const res = await MiviaApi.language.list();
            if (res.data) {
              dispatch(setLanguages(res.data));
            }
          } catch (e: any) {}
        })();
      }

      if (!currencies) {
        (async () => {
          try {
            const res = await MiviaApi.currency.list();

            if (res.data) {
              dispatch(setCurrencies(res.data));
            }
          } catch (e: any) {}
        })();
      }
    }

    if (!jwt && localJwt) {
      dispatch(setJwt(localJwt));
    }
  }, [jwt]);

  if (!jwt && !localJwt) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Container
        maxW={"100vw"}
        maxHeight={"100vw"}
        minHeight="100vh"
        p={0}
        style={{ position: "relative", maxHeight: "100vw" }}
      >
        <AppNavbar />

        <AppSidebar />

        <div className="app-main">
          <Outlet />
        </div>
        {/* <Flex minWidth="max-content" flexDirection={"column"} minHeight="100vh">
          <Flex
            minWidth="max-content"
            flex={1}
            style={{ position: "relative" }}
          >
            <Box w={"xs"} maxW={"300px"} style={{ position: "relative" }}></Box>
            <Box flex="1">
              <Outlet />
            </Box>
          </Flex> */}

        {/* <Box>
            <Flex minWidth="max-content" flexDirection={"row"}>
              <Box flex={1}>
                <Text fontSize={"sm"} p={4}>
                  Coopyright (&copy;) by{" "}
                  <a href="https://mivia.net" target="_blank" rel="noreferrer">
                    <strong>mivia.net</strong>
                  </a>
                  . All rights reserved.
                </Text>
              </Box>
              <Box>
                <Text fontSize={"sm"} p={4}>
                  <Link to={"/dashboard"}>Dashboard</Link> |{" "}
                  <a
                    href="https://docs.mivia.net"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Documentation
                  </a>{" "}
                  | <Link to={"/support"}>Support</Link>
                </Text>
              </Box>
            </Flex>
          </Box> */}
        {/* </Flex> */}
      </Container>
    </>
  );
};

export default RootApp;
