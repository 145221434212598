import { MiviaApi } from "../mivia-api";
import { HttpMethod } from "../enums";
import { AuthMethod, AuthMethodUpdatePassword, Response } from "../interfaces";
import { AuthMethodCreate } from "../interfaces/auth/auth-method-creae.interface";

export class AuthMethods {
  async list(): Promise<Response<AuthMethod[]>> {
    const res = await MiviaApi.client.call<null, AuthMethod[], null>(
      HttpMethod.GET,
      "auth/method"
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<AuthMethod[]>(res?.data, res?.meta);
  }

  async get(id: string): Promise<Response<AuthMethod>> {
    const res = await MiviaApi.client.call<undefined, AuthMethod, undefined>(
      HttpMethod.POST,
      `auth/method/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<AuthMethod>(res?.data, res?.meta);
  }

  async create(payload: AuthMethodCreate): Promise<Response<AuthMethod>> {
    const res = await MiviaApi.client.call<AuthMethodCreate, AuthMethod, null>(
      HttpMethod.POST,
      "auth/method",
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<AuthMethod>(res?.data, res?.meta);
  }

  async activate(confirmationCode: string): Promise<Response<void>> {
    const res = await MiviaApi.client.call<null, null, null>(
      HttpMethod.GET,
      `auth/confirm/${confirmationCode}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<void>(undefined, undefined);
  }

  async delete(id: string): Promise<Response<AuthMethod>> {
    const res = await MiviaApi.client.call<undefined, AuthMethod, undefined>(
      HttpMethod.DELETE,
      `auth/method/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<AuthMethod>(res?.data, res?.meta);
  }

  async updatePassword(
    authMethodId: string,
    payload: AuthMethodUpdatePassword
  ): Promise<Response<AuthMethod>> {
    const res = await MiviaApi.client.call<
      AuthMethodUpdatePassword,
      AuthMethod,
      null
    >(HttpMethod.PUT, `auth/method/${authMethodId}/credentials`, payload);

    if (res?.error) {
      throw res;
    }

    return Response.create<AuthMethod>(res?.data, res?.meta);
  }
}
