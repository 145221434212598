import { HttpMethod } from "../enums/http-method.enum";
import { MiviaApi } from "../mivia-api";
import { Response } from "../interfaces/response.interface";
import { WarehouseLocation, WarehouseLocationCreate } from "../interfaces";
import { WarehouseLocationUpdate } from "../interfaces/ecommerce/warehouse-location-update";

export class WarehousesLocations {
  async list(warehouseId: string): Promise<Response<WarehouseLocation[]>> {
    const res = await MiviaApi.client.call<null, WarehouseLocation[], null>(
      HttpMethod.GET,
      `warehouse/${warehouseId}/location`,
      null,
      null
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<WarehouseLocation[]>(res?.data, res?.meta);
  }

  async get(
    warehouseId: string,
    locationId: string
  ): Promise<Response<WarehouseLocation>> {
    const res = await MiviaApi.client.call<null, WarehouseLocation, null>(
      HttpMethod.GET,
      `warehouse/${warehouseId}/location/${locationId}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<WarehouseLocation>(res?.data, res?.meta);
  }

  async create(
    warehouseId: string,
    payload: WarehouseLocationCreate
  ): Promise<Response<WarehouseLocation>> {
    const res = await MiviaApi.client.call<
      WarehouseLocationCreate,
      WarehouseLocation,
      null
    >(HttpMethod.POST, `warehouse/${warehouseId}/location`, payload);

    if (res?.error) {
      throw res;
    }

    return Response.create<WarehouseLocation>(res?.data, res?.meta);
  }

  async update(
    warehouseId: string,
    locationId: string,
    payload: WarehouseLocationUpdate
  ): Promise<Response<WarehouseLocation>> {
    const res = await MiviaApi.client.call<
      WarehouseLocationUpdate,
      WarehouseLocation,
      null
    >(
      HttpMethod.PUT,
      `warehouse/${warehouseId}/location/${locationId}`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<WarehouseLocation>(res?.data, res?.meta);
  }

  async delete(
    warehouseId: string,
    locationId: string
  ): Promise<Response<WarehouseLocation>> {
    const res = await MiviaApi.client.call<null, WarehouseLocation, null>(
      HttpMethod.DELETE,
      `warehouse/${warehouseId}/location/${locationId}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<WarehouseLocation>(res?.data, res?.meta);
  }
}
