import { ResponseError } from "./response-error.interface";

export class Response<ResponseDataType> {
  error?: ResponseError;
  data?: ResponseDataType;
  meta?: ResponseMeta;

  static create<ResponseDataType>(
    data?: ResponseDataType,
    meta?: ResponseMeta
  ): Response<ResponseDataType> {
    const r = new Response<ResponseDataType>();
    r.data = data;
    r.meta = meta;

    return r;
  }
}

export interface ResponseMeta {
  total?: number;
}
