import { MiviaApi } from "../mivia-api";
import { HttpMethod } from "../enums";
import { Response } from "../interfaces/response.interface";
import { Warehouse, WarehouseCreate, WarehouseUpdate } from "../interfaces";
import { WarehouseListQuery } from "../interfaces/warehouse/warehouse-list-query.interface";
import { WarehousesLocations } from "./warehouse-locations";

export class Warehouses {
  location: WarehousesLocations = new WarehousesLocations();

  /**
   * List all WAREHOUSES in provided PROJECTS to which USER / API KEY has access to
   *
   * @param projectsIds string[]
   * @returns Warehouse[]
   */
  async list(projectIds: string[]): Promise<Response<Warehouse[]>> {
    const query: WarehouseListQuery = {
      projectIds: projectIds,
    };

    const res = await MiviaApi.client.call<
      null,
      Warehouse[],
      WarehouseListQuery
    >(HttpMethod.GET, "warehouse", null, query);

    if (res?.error) {
      throw res;
    }

    return Response.create<Warehouse[]>(res?.data, res?.meta);
  }

  /**
   * Get WAREHOUSE by id
   *
   * @param id warehouse id
   * @returns Warehouse
   */
  async get(id: string): Promise<Response<Warehouse>> {
    const res = await MiviaApi.client.call<null, Warehouse, null>(
      HttpMethod.GET,
      `warehouse/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Warehouse>(res?.data, res?.meta);
  }

  /**
   * Create WAREHOUSE
   *
   * @param payload WarehouseCreate
   * @returns Warehouse
   */
  async create(payload: WarehouseCreate): Promise<Response<Warehouse>> {
    const res = await MiviaApi.client.call<WarehouseCreate, Warehouse, null>(
      HttpMethod.POST,
      `warehouse`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Warehouse>(res?.data, res?.meta);
  }

  /**
   * Update WAREHOUSE
   *
   * @param warehouseId WAREHOUSE id
   * @param payload WarehouseUpdate
   * @returns Warehouse
   */
  async update(
    warehouseId: string,
    payload: WarehouseUpdate
  ): Promise<Response<Warehouse>> {
    const res = await MiviaApi.client.call<WarehouseUpdate, Warehouse, null>(
      HttpMethod.PUT,
      `warehouse/${warehouseId}`,
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Warehouse>(res?.data, res?.meta);
  }

  /**
   * Delete WAREHOUSE by id
   *
   * @param id
   * @returns Warehouse
   */
  async delete(id: string): Promise<Response<Warehouse>> {
    const res = await MiviaApi.client.call<null, Warehouse, null>(
      HttpMethod.DELETE,
      `warehouse/${id}`
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<Warehouse>(res?.data, res?.meta);
  }
}
