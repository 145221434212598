import { Container, Text } from "@chakra-ui/react";
import PublicLoginForm from "../../components/public/public-login.form";

const PublicHomeView = () => {
  return (
    <>
      <Container maxW="2xl">
        <PublicLoginForm />

        <Text fontSize={"sm"} p={4}>
          Coopyright (&copy;) by{" "}
          <a href="https://mivia.net" target="_blank">
            <strong>mivia.net</strong>
          </a>
          . All rights reserved.
        </Text>
      </Container>
    </>
  );
};

export default PublicHomeView;
