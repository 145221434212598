import { Auth } from "./auth";
import { Client } from "./client";
import { Currencies } from "./currency";
import { Commerce } from "./commerce";
import { Files } from "./file";
import { MiviaApiOptions } from "./interfaces";
import { Languages } from "./language";
import { Organizations } from "./organization";
import { Projects } from "./project";

/**
 * Mivia API class
 */
export class MiviaApi {
  static client: Client = new Client();
  static auth: Auth = new Auth();
  static organization: Organizations = new Organizations();
  static project: Projects = new Projects();
  static file: Files = new Files();
  static commerce: Commerce = new Commerce();
  static language: Languages = new Languages();
  static currency: Currencies = new Currencies();

  static config: MiviaApiOptions = {
    url: "https://api.mivia.com",
    version: "v1",
  };

  constructor() {
    throw new Error(`Direct class initialization is not allowed.`);
  }

  static setConfig(config: MiviaApiOptions): void {
    if (config.url) {
      MiviaApi.config.url = config.url;
    }

    if (config.version) {
      MiviaApi.config.version = config.version;
    }

    if (config.api) {
      MiviaApi.config.api = {
        keyPublic: config.api.keyPublic,
        keySecret: config.api.keySecret,
      };
    }

    if (config.auth) {
      MiviaApi.config.auth = {
        token: config.auth.token,
      };
    }
  }
}
