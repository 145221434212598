import { Select } from "@chakra-ui/react";
import { selectUser } from "../../../store/auth";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useEffect, useState } from "react";
import {
  setCurrentOrganizationId,
  setCurrentProjectId,
} from "../../../store/app";
import { LocalStore } from "../../store";

const SelectOrganization = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const currentContext = useAppSelector((state) => state.app.currentContext);

  const userOrganizations: any[] = [];
  // @ts-ignore
  const userRolesWithOrganizations = user?.roles.filter(
    (r: any) => r.organization !== null
  );
  const [currentOrganizationId, setOrganizationId] = useState("");

  userRolesWithOrganizations?.forEach((r: any) => {
    if (
      userOrganizations.filter((o: any) => o.id === r.organization.id).length >
      0
    ) {
      return;
    }

    userOrganizations.push(r.organization);
  });

  useEffect(() => {
    if (
      currentContext.organizationId &&
      currentOrganizationId !== currentContext.organizationId
    ) {
      setOrganizationId(currentContext.organizationId);
    }
  }, [currentContext.organizationId, currentOrganizationId]);

  const UserOrganization = (props: { organization: any; keyIdx: number }) => {
    return (
      <>
        <option value={props.organization.id} key={props.keyIdx}>
          {props.organization.name}
        </option>
      </>
    );
  };

  return (
    <>
      <Select
        variant="outline"
        placeholder="Select Organization"
        size={"sm"}
        maxW={"200px"}
        display={"inline-block"}
        value={currentOrganizationId}
        onChange={(e: any) => {
          LocalStore.set("currentOrganizationId", e.target.value);

          dispatch(setCurrentOrganizationId(e.target.value));
          dispatch(setCurrentProjectId(""));

          setOrganizationId(e.target.value);
        }}
      >
        {userOrganizations.map((o: any, idx: number) => (
          <UserOrganization organization={o} key={idx} keyIdx={idx} />
        ))}
      </Select>
    </>
  );
};

export default SelectOrganization;
