import { ApiBase } from "../api-base";
import { HttpMethod } from "../enums/http-method.enum";
import { Response } from "../interfaces/index";
import { ConfirmPasswordResetBody } from "../interfaces/auth/confirm-password-reset-body.interface";
import { LoginByEmailBody } from "../interfaces/auth/login-by-email-body.interface";
import { LoginResponse } from "../interfaces/auth/login-response.interface";
import { RegisterByEmailBody } from "../interfaces/auth/register-by-email-body.interface";
import { RequestPasswordResetBody } from "../interfaces/auth/request-password-reset-body.interface";
import { Me } from "./me";
import { MiviaApi } from "../mivia-api";
import { Api } from "./api";
import { AuthMethods } from "./auth-methods";

export class Auth extends ApiBase {
  readonly me: Me = new Me();
  readonly api: Api = new Api();
  readonly method: AuthMethods = new AuthMethods();

  /**
   * Authenticate user by email and password.
   *
   * @param email user email address
   * @param password password to user account
   * @returns LoginResponse
   */
  async loginByEmail(
    email: string,
    password: string
  ): Promise<Response<LoginResponse>> {
    const body: LoginByEmailBody = {
      email,
      password,
    };

    const res = await MiviaApi.client.call<
      LoginByEmailBody,
      LoginResponse,
      null
    >(HttpMethod.POST, "auth/email", body);

    if (res?.error) {
      throw res;
    }

    return Response.create(res?.data, res?.meta);
  }

  /**
   * Register USER account by email and password
   * @param payload RegisterByEmailBody
   * @returns void
   */
  async registerByEmail(payload: RegisterByEmailBody): Promise<void | any> {
    const res = await MiviaApi.client.call<RegisterByEmailBody, void, null>(
      HttpMethod.POST,
      "auth/register/email",
      payload
    );

    if (res?.error) {
      throw res;
    }
  }

  /**
   * Confirm registration by providing confirmation code
   *
   * @param confirmationCode string
   * @returns void
   */
  async confirmRegistration(confirmationCode: string): Promise<void> {
    const res = await MiviaApi.client.call<void, void, null>(
      HttpMethod.GET,
      `auth/confirm/${confirmationCode}`
    );

    if (res?.error) {
      throw res;
    }
  }

  /**
   * Request password reset for a USER account
   *
   * @param payload RequestPasswordResetBody
   */
  async requestPasswordReset(payload: RequestPasswordResetBody): Promise<void> {
    const res = await MiviaApi.client.call<
      RequestPasswordResetBody,
      void,
      null
    >(HttpMethod.POST, `auth/password-reset/request`, payload);

    if (res?.error) {
      throw res;
    }
  }

  /**
   * Confirm password reset request by providing request code and new password.
   *
   * @param payload ConfirmPasswordResetBody
   */
  async confirmPasswordReset(payload: ConfirmPasswordResetBody): Promise<void> {
    const res = await MiviaApi.client.call<
      ConfirmPasswordResetBody,
      void,
      null
    >(HttpMethod.POST, `auth/password-reset/confirm`, payload);

    if (res?.error) {
      throw res;
    }
  }
}
