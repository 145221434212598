import { createBrowserRouter } from "react-router-dom";
import Root from "../app/root";
import RootPublic from "../app/views/public/root-public";
import RootApp from "../app/views/app/root-app";
import PublicHomeView from "../app/views/public/public-home.view";
import PublicLogoutView from "../app/views/public/public-logout.view";
import AppSettingsView from "../app/views/app/settings/app-settings.view";
import ProductsView from "../app/views/app/ecommerce/products/products.view";
import OrdersView from "../app/views/app/ecommerce/orders/orders.view";
import StocksView from "../app/views/app/ecommerce/stocks/stocks.view";
import CustomersView from "../app/views/app/ecommerce/customers/customers.view";
import SuppliersView from "../app/views/app/ecommerce/suppliers/suppliers.view";
import ShippingView from "../app/views/app/ecommerce/shipping/shipping.view";
import BrandsView from "../app/views/app/ecommerce/brands/brands.view";
import WarehousesView from "../app/views/app/ecommerce/warehouses/warehouses.view";
// import DatasetTemplateView from "../app/views/app/dataset/dataset-templates.view";
// import CreateDatasetTemplateView from "../app/views/app/dataset/create-dataset-template.view";
import React, { Suspense } from "react";
import { ViewLoader } from "../app/components/app/view-loader";

////////
const PublicRegisterView = React.lazy(
  () => import("../app/views/public/public-register.view")
);
const PublicActivateView = React.lazy(
  () => import("../app/views/public/public-activate.view")
);
const PasswordResetRequestView = React.lazy(
  () => import("../app/views/public/password-reset/password-reset-request.view")
);
const ConfirmPasswordResetRequestView = React.lazy(
  () =>
    import(
      "../app/views/public/password-reset/confirm-password-reset-request.view"
    )
);
const AppDashboardView = React.lazy(
  () => import("../app/views/app/app-dashboard.view")
);

const AppSettingsOrganizationsCreateView = React.lazy(
  () =>
    import("../app/views/app/settings/app-settings-organizations-create.view")
);
const AppSettingsOrganizationsInvitationsView = React.lazy(
  () =>
    import(
      "../app/views/app/settings/app-settings-organizations-invitations.view"
    )
);
const AppSettingsOrganizationsDetailsView = React.lazy(
  () =>
    import("../app/views/app/settings/app-settings-organizations-details.view")
);
const AppSettingsProjectDetailsView = React.lazy(
  () => import("../app/views/app/settings/app-settings-project-details.view")
);

const AppSettingsOrganizationsView = React.lazy(
  () => import("../app/views/app/settings/app-settings-organizations.view")
);
const AppSettingsProjectView = React.lazy(
  () => import("../app/views/app/settings/app-settings-project.view")
);
const AppSettingsProjectCreateView = React.lazy(
  () => import("../app/views/app/settings/app-settings-project-create.view")
);
const AppSettingsBillingView = React.lazy(
  () => import("../app/views/app/settings/app-settings-billing.view")
);

const AppSettingsApiView = React.lazy(
  () => import("../app/views/app/settings/app-settings-api.view")
);
const AppSettingsApiCreateView = React.lazy(
  () => import("../app/views/app/settings/app-settings-api-create.view")
);
const AppSettingsApiEditView = React.lazy(
  () => import("../app/views/app/settings/app-settings-api-edit.view")
);
const AppSettingsHomeView = React.lazy(
  () => import("../app/views/app/settings/app-settings-home.view")
);

const AppSettingsAuthMethodAddView = React.lazy(
  () => import("../app/views/app/settings/app-settings-auth-method-add")
);
const AppSettingsAuthMethodEditView = React.lazy(
  () => import("../app/views/app/settings/app-settings-auth-method-edit")
);

const EcommerceSettingsView = React.lazy(
  () => import("../app/views/app/ecommerce/ecommerce-settings.view")
);
const ShippingListView = React.lazy(
  () => import("../app/views/app/ecommerce/shipping/shipping-list.view")
);
const CustomersListView = React.lazy(
  () => import("../app/views/app/ecommerce/customers/customers-list.view")
);
const StocksListView = React.lazy(
  () => import("../app/views/app/ecommerce/stocks/stocks-list.view")
);
const OrdersListView = React.lazy(
  () => import("../app/views/app/ecommerce/orders/orders-list.view")
);

/// PRODUCTS
const ProductsCreateView = React.lazy(
  () => import("../app/views/app/ecommerce/products/products-create.view")
);
const ProductsListView = React.lazy(
  () => import("../app/views/app/ecommerce/products/products-list.view")
);
const ProductsEditView = React.lazy(
  () => import("../app/views/app/ecommerce/products/products-edit.view")
);

/// SUPPLIERS
const SuppliersListView = React.lazy(
  () => import("../app/views/app/ecommerce/suppliers/suppliers-list.view")
);
const SuppliersCreateView = React.lazy(
  () => import("../app/views/app/ecommerce/suppliers/suppliers-create.view")
);
const SupplierEditView = React.lazy(
  () => import("../app/views/app/ecommerce/suppliers/supplier-edit.view")
);

/// BRANDS
const BrandsListView = React.lazy(
  () => import("../app/views/app/ecommerce/brands/brands-list.view")
);

const BrandCreateView = React.lazy(
  () => import("../app/views/app/ecommerce/brands/brand-create.view")
);

const BrandEditView = React.lazy(
  () => import("../app/views/app/ecommerce/brands/brand-edit.view")
);

/// WAREHOUSES
const WarehousesListView = React.lazy(
  () => import("../app/views/app/ecommerce/warehouses/warehouses-list.view")
);
const WarehouseCreateView = React.lazy(
  () => import("../app/views/app/ecommerce/warehouses/warehouse-create.view")
);
const WarehouseEditView = React.lazy(
  () => import("../app/views/app/ecommerce/warehouses/warehouse-edit.view")
);

const router = createBrowserRouter([
  {
    // @ts-ignore
    element: <Root />,
    children: [
      {
        element: <RootPublic />,
        children: [
          { path: "/", element: <PublicHomeView /> },
          {
            path: "/register",
            element: (
              <Suspense fallback={<ViewLoader />}>
                <PublicRegisterView />
              </Suspense>
            ),
          },
          { path: "/logout", element: <PublicLogoutView /> },
          {
            path: "/activate/:activationCode?",
            element: (
              <Suspense fallback={<ViewLoader />}>
                <PublicActivateView />
              </Suspense>
            ),
          },
          {
            path: "/password-reset",
            element: (
              <Suspense fallback={<ViewLoader />}>
                <PasswordResetRequestView />
              </Suspense>
            ),
          },
          {
            path: "/password-reset/confirm/:requestCode",
            element: (
              <Suspense fallback={<ViewLoader />}>
                <ConfirmPasswordResetRequestView />
              </Suspense>
            ),
          },
        ],
      },
      {
        element: <RootApp />,
        children: [
          {
            path: "/dashboard",
            element: (
              <Suspense fallback={<ViewLoader />}>
                <AppDashboardView />
              </Suspense>
            ),
          },

          {
            path: "/commerce/product",
            element: <ProductsView />,
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <ProductsListView />
                  </Suspense>
                ),
              },
              {
                path: "create",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <ProductsCreateView />
                  </Suspense>
                ),
              },
              {
                path: ":productId",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <ProductsEditView />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "/commerce/order",
            element: <OrdersView />,
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <OrdersListView />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "/commerce/stocks",
            element: <StocksView />,
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <StocksListView />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "/commerce/customer",
            element: <CustomersView />,
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <CustomersListView />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "/commerce/supplier",
            element: <SuppliersView />,
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <SuppliersListView />
                  </Suspense>
                ),
              },
              {
                path: "create",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <SuppliersCreateView />
                  </Suspense>
                ),
              },
              {
                path: ":supplierId",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <SupplierEditView />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "/commerce/brand",
            element: <BrandsView />,
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <BrandsListView />
                  </Suspense>
                ),
              },
              {
                path: "create",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <BrandCreateView />
                  </Suspense>
                ),
              },
              {
                path: ":brandId",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <BrandEditView />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "/commerce/shipping",
            element: <ShippingView />,
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <ShippingListView />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "/commerce/warehouse",
            element: <WarehousesView />,
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <WarehousesListView />
                  </Suspense>
                ),
              },
              {
                path: "create",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <WarehouseCreateView />
                  </Suspense>
                ),
              },
              {
                path: ":warehouseId",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <WarehouseEditView />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: "/commerce/settings",
            element: (
              <Suspense fallback={<ViewLoader />}>
                <EcommerceSettingsView />
              </Suspense>
            ),
          },
          // {
          //   path: "/dataset/template",
          //   element: <DatasetTemplateView />,
          // },
          // {
          //   path: "/dataset/template/create",
          //   element: <CreateDatasetTemplateView />,
          // },
          {
            path: "/settings",
            element: <AppSettingsView />,
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsHomeView />
                  </Suspense>
                ),
              },
              {
                path: "billing",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsBillingView />
                  </Suspense>
                ),
              },

              {
                path: "api",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsApiView />
                  </Suspense>
                ),
              },
              {
                path: "api/create",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsApiCreateView />
                  </Suspense>
                ),
              },
              {
                path: "api/:apiKeyId",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    {" "}
                    <AppSettingsApiEditView />
                  </Suspense>
                ),
              },

              {
                path: "organization",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsOrganizationsView />
                  </Suspense>
                ),
              },
              {
                path: "organization/create",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsOrganizationsCreateView />
                  </Suspense>
                ),
              },
              {
                path: "organization/invitation",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsOrganizationsInvitationsView />
                  </Suspense>
                ),
              },
              {
                path: "organization/:organizationId",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    {" "}
                    <AppSettingsOrganizationsDetailsView />
                  </Suspense>
                ),
              },
              {
                path: "project",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsProjectView />
                  </Suspense>
                ),
              },
              {
                path: "project/create",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsProjectCreateView />
                  </Suspense>
                ),
              },
              {
                path: "project/:projectId",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsProjectDetailsView />
                  </Suspense>
                ),
              },
              {
                path: "auth/create",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsAuthMethodAddView />
                  </Suspense>
                ),
              },
              {
                path: "auth/:authMethodId",
                element: (
                  <Suspense fallback={<ViewLoader />}>
                    <AppSettingsAuthMethodEditView />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   element: <AppRoot />,
  //   children: [
  //     {
  //       element: <AppPublic />,
  //       children: [
  //         { path: "/", element: <LoginView /> },
  //         { path: "/register", element: <RegisterView /> },
  //         { path: "/activate/:activationCode?", element: <ActivateView /> },
  //       ],
  //     },
  //     {
  //       element: <App />,
  //       children: [
  //         {
  //           path: "/dashboard",
  //           element: <Dashboard />,
  //         },
  //         {
  //           path: "/organization/:organizationId",
  //           element: <OrganizationView />,
  //           children: [
  //             {
  //               path: "",
  //               element: <OrganizationHomeView />,
  //             },
  //             {
  //               path: "members",
  //               element: <OrganizationMembersView />,
  //             },
  //             {
  //               path: "settings",
  //               element: <OrganizationSettingsView />,
  //             },
  //           ],
  //         },
  //         {
  //           path: "/project",
  //           element: <Projects />,
  //           children: [
  //             {
  //               path: "",
  //               element: <ProjectHome />,
  //             },
  //             {
  //               path: "create",
  //               element: <ProjectCreate />,
  //             },
  //             {
  //               path: ":projectId",
  //               element: <ProjectView />,
  //             },
  //           ],
  //         },
  //         {
  //           path: "/settings",
  //           element: <Settings />,
  //           children: [
  //             {
  //               path: "",
  //               element: <SettingsGeneral />,
  //             },
  //             {
  //               path: "organizations",
  //               element: <Organizations />,
  //             },
  //             {
  //               path: "organizations/new",
  //               element: <NewOrganizationView />,
  //             },
  //             {
  //               path: "webhooks",
  //               element: <SettingsWebhooksView />,
  //             },
  //             {
  //               path: "api",
  //               element: <SettingsApiView />,
  //             },
  //             {
  //               path: "billing",
  //               element: <SettingsBillingView />,
  //             },
  //           ],
  //         },

  //         {
  //           path: "/community-vaults",
  //           element: <CommunityVaults />,
  //         },
  //         {
  //           path: "/members",
  //           element: <Members />,
  //         },

  //         // {
  //         //   path: "/farms",
  //         //   element: <Farms />,
  //         // },

  //         // {
  //         //   element: <Admin />,
  //         //   children: [
  //         //     {
  //         //       path: "/admin",
  //         //       element: <AdminDashboard />,
  //         //     },
  //         //     {
  //         //       path: "/admin/contracts/chainfarm",
  //         //       element: <AdminChainFarm />,
  //         //     },
  //         //     {
  //         //       path: "/admin/contracts/treasury",
  //         //       element: <AdminTreasury />,
  //         //     },
  //         //     {
  //         //       path: "/admin/contracts/farmmanager",
  //         //       element: <AdminFarmManager />,
  //         //     },
  //         //     {
  //         //       path: "/admin/contracts/farms",
  //         //       element: <AdminFarms />,
  //         //     },

  //         //     {
  //         //       path: "/admin/contracts/dexmanager",
  //         //       element: <AdminDexManager />,
  //         //     },

  //         //     {
  //         //       path: "/admin/pulsex",
  //         //       element: <AdminPulseX />,
  //         //     },
  //         //     //     {
  //         //     //       path: "/admin/farms",
  //         //     //       element: <Farms />,
  //         //     //     },
  //         //     //     {
  //         //     //       path: "/admin/farms/settings",
  //         //     //       element: <FarmManagerSettingsView />,
  //         //     //     },
  //         // ],
  //         // },
  //       ],
  //     },
  //   ],
  // },
]);

export default router;
