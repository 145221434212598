import { MiviaApi } from "../mivia-api";
import { Currency, FileUpload, Response } from "../interfaces";
import { HttpMethod } from "../enums";

export class Files {
  async upload(body: FormData): Promise<Response<any[]>> {
    const res = await MiviaApi.client.call<FormData, any[], null>(
      HttpMethod.POST,
      `file`,
      undefined,
      null,
      body
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<any[]>(res?.data, res?.meta);
  }
}
