import { AuthMethod, Me } from "@mivialabs/mivia-api-nodejs";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AuthState {
  jwt: string | null;
  user: Me | null;
}

export interface UserState extends Me {}

const initialState: AuthState = {
  jwt: null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setJwt: (state, action: PayloadAction<string | null>) => {
      state.jwt = action.payload;
    },
    setUser: (state, action: PayloadAction<UserState | null>) => {
      state.user = action.payload;
    },
    setUserAuthMethods: (state, action: PayloadAction<AuthMethod[]>) => {
      if (state.user) {
        state.user.authMethods = action.payload;
      }
    },
  },
});

export const { setJwt, setUser, setUserAuthMethods } = authSlice.actions;

export const selectJwt = (state: AuthState) => state.jwt;
export const selectUser = (state: AuthState) => state.user;
export const selectUserAuthMethods = (state: AuthState) =>
  state.user?.authMethods;

export default authSlice.reducer;
