import { Avatar, Box, Button, ButtonGroup, Flex } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import SelectOrganization from "./select-organization";
import SelectProject from "./select-project";
import { useAppSelector } from "../../../store";
import { stat } from "fs";
import AppSubNavbar from "./app-subnavbar";

const AppNavbar = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  return (
    <>
      <Box className="app-navbar">
        <Flex minWidth="max-content" flexDirection={"row"}>
          <Box flex={1} maxW={"300px"} pl={3} pr={3}>
            <Link to="/dashboard" className="logo" title="Go to Dashboard">
              mivia
            </Link>
          </Box>

          <Box flex={1}>{/* <SelectOrganization /> <SelectProject /> */}</Box>

          <Box>
            {/* <Button variant={"ghost"} size={"sm"}></Button>{" "} */}
            <ButtonGroup size="sm" isAttached variant="outline">
              <Button
                size={"sm"}
                bg={`#4366FF`}
                color={`#ffffff`}
                onClick={() => {
                  navigate("/settings");
                }}
              >
                <Avatar size="xs" src={""}>
                  {/* <AvatarBadge boxSize="1.9em" bg="blue.500">
                    22
                  </AvatarBadge> */}
                </Avatar>{" "}
                &nbsp; {user?.displayName ? user.displayName : `Profile`}
              </Button>
              <Button
                size={"sm"}
                onClick={() => {
                  navigate("/logout");
                }}
              >
                Logut
              </Button>
            </ButtonGroup>
          </Box>
        </Flex>
      </Box>
      {/* <AppSubNavbar /> */}
    </>
  );
};

export default AppNavbar;
