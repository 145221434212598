import { ApiBase } from "../api-base";
import { Response } from "../interfaces/index";
import { MeSettingsUpdate } from "../interfaces/auth/me-settings-update.interface";
import { MeSettings } from "../interfaces/auth/me-settings.interface";
import { Me as MeDetails } from "../interfaces/auth/me.interface";
import { MiviaApi } from "../../src";
import { HttpMethod } from "../../src/enums/http-method.enum";

export class Me extends ApiBase {
  /**
   * Get details about current USER
   *
   * @returns MeDetails
   */
  async get(): Promise<Response<MeDetails>> {
    const res = await MiviaApi.client.call<null, MeDetails, null>(
      HttpMethod.GET,
      "auth/me"
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<MeDetails>(res?.data, res?.meta);
  }

  async delete(): Promise<void> {
    const res = await MiviaApi.client.call<null, MeDetails, null>(
      HttpMethod.DELETE,
      "auth/me"
    );

    if (res?.error) {
      throw res;
    }

    return;
  }

  /**
   * Get current USER settings
   *
   * @returns MeSettings
   */
  async getSettings(): Promise<Response<MeSettings>> {
    const res = await MiviaApi.client.call<null, MeSettings, null>(
      HttpMethod.GET,
      "auth/me/settings"
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<MeSettings>(res?.data, res?.meta);
  }

  /**
   * Update current USER settings
   *
   * @param payload MeSettingsUpdate
   * @returns MeSettings
   */
  async updateSettings(
    payload: MeSettingsUpdate
  ): Promise<Response<MeDetails>> {
    const res = await MiviaApi.client.call<MeSettingsUpdate, MeDetails, null>(
      HttpMethod.PATCH,
      "auth/me/settings",
      payload
    );

    if (res?.error) {
      throw res;
    }

    return Response.create<MeDetails>(res?.data, res?.meta);
  }
}
